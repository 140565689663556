import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MastercardAmountBonus } from './AmountBonus';

const bonuses = [
  {
    amount: 200,
    bonus: 50,
    className: 'bg-mastercard-yellow !border-mastercard-yellow',
  },
  {
    amount: 500,
    bonus: 75,
    className: 'bg-custom-2 !border-custom-2',
  },
  {
    amount: 1000,
    bonus: 100,
    className: 'bg-light-red !border-light-red',
  },
];

export const InfoSection = () => {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto lg:max-w-[1280px] py-[50px] lg:py-[70px] 2xl:py-[130px]">
      <p
        style={{fontSize: '24px', fontWeight: 600}}
        className="text-md info-title"
        dangerouslySetInnerHTML={{ __html: t('helping-together-info-title') }}
      />
      <br />
      <br />
      <p
        className="text-md"
        dangerouslySetInnerHTML={{ __html: t('helping-together-info-desc') }}
      />

      
    </div>
  );
};
