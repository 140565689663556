import React from 'react';

import { HelpingTogether } from '@widgets/events/mastercard/HelpingTogether';
import { InfoSection } from '@widgets/events/mastercard/InfoSection';
import { HowToHelpWithKlopotenko } from '@widgets/events/mastercard/HowToHelpWithKlopotenko';
import { LetsHelpTogether } from '@widgets/events/mastercard/LetsHelpTogether';

const MastercardEventPage = () => {
  return (
    <div>
      <HelpingTogether />
      <InfoSection />
      <HowToHelpWithKlopotenko />
      <LetsHelpTogether />
    </div>
  );
};

export default MastercardEventPage;
