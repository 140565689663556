import { Color } from '@shared/types/common.types';
import { Button } from '@shared/ui/buttons/Button';
import { Header } from '@shared/ui/headers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MastercardStepBlock } from './StepBlock';

interface StepsConfig {
  color: Color;
  secondaryColor: Color;
  content: (t: (value: string) => string, language: string) => JSX.Element;
}

const steps: StepsConfig[] = [
  {
    color: 'mastercard-yellow',
    secondaryColor: 'mastercard-yellow',
    content: (t: (value: string) => string, language: string) => {
      return (
        <div className="grow flex flex-col items-center">
          <p 
            className="text-center grow" 
            dangerouslySetInnerHTML={{__html: t('how-to-help-1')}} 
          />
          <Button
            style={{borderRadius: 8}}
            as="link-out"
            href={`https://social.edopomoga.gov.ua/${language}/auth/sign-up`}
            size="small"
            className="mt-3"
          >
            
            {t('register')}
          </Button>
        </div>
      );
    },
  },
  {
    color: 'custom-2',
    secondaryColor: 'custom-2',
    content: (t: (value: string) => string, language: string) => {
      return (
        <div className="grow flex flex-col items-center">
          <p className="text-center">{t('how-to-help-2')}</p>
        </div>
      );
    },
  },
  {
    color: 'light-red',
    secondaryColor: 'light-red',
    content: (t: (value: string) => string, language: string) => {
      return (
        <div className="grow flex flex-col items-center">
          <p className="text-center grow">{t('how-to-help-3')}</p>
          <Button
            style={{borderRadius: 8}}
            as="link-out"
            href={`https://social.edopomoga.gov.ua/${language}/cabinet/volunteer/?category=online-food`}
            size="small"
            className="mt-3"
          >
            {t('pay')}
          </Button>
        </div>
      );
    },
  },
  {
    color: 'light-red',
    secondaryColor: 'light-red',
    content: (t: (value: string) => string, language: string) => {
      return (
        <div className="grow flex flex-col items-center">
          <p className="text-center grow">{t('how-to-help-4')}</p>
        </div>
      );
    },
  },
];

export const HowToHelpWithKlopotenko = () => {
  const {
    t,
    i18n: { resolvedLanguage, language },
  } = useTranslation();

  return (
    <div className="mb-50px xl:mb-[100px]">
      <div className="container mx-auto lg:max-w-[1280px] mb-28">
        <Header text={t('how-to-help')} className="text-3xl lg:text-5xl" />
      </div>

      <div className="container_2xl mx-auto mb-[60px]">
        <div className="grid lg:grid-cols-4 gap-y-20 lg:gap-8">
          {steps.map(({ color, secondaryColor, content }, idx) => (
            <MastercardStepBlock
              key={idx}
              color={color}
              secondaryColor={secondaryColor}
              children={content(t, resolvedLanguage || language)}
              position={idx + 1}
              whiteBg
            />
          ))}
        </div>
      </div>
    </div>
  );
};
