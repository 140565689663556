import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@shared/ui/buttons/Button';
import { Text } from '@shared/ui/text';
import { useRules } from './useRules';
import styles from './LetsHelpTogether.module.css';

const textClassName = 'text-3xl md:text-4xl lg:text-5xl block';

export const LetsHelpTogether = () => {
  const {
    t,
    i18n: { resolvedLanguage, language },
  } = useTranslation();

  const { rulesDocLink } = useRules();

  const linkUrl = `https://social.edopomoga.gov.ua/${
    resolvedLanguage || language
  }/cabinet/volunteer/?category=online-food`;

  return (
    <div className="container mx-auto flex flex-col items-center lg:max-w-[1280px] mb-3.125 md:mb-5.875">
      <div className="mb-20">
        <Text component="span" align="center" className={textClassName}>
          {t('help-and-improve')}
        </Text>
      
        <div style={{display: 'flex', width: '100%',marginTop: 40, justifyContent: 'center'}} >
          <Button as="link-out" href={linkUrl} size="small" style={{color: 'black',  backgroundColor: '#FF5F00',  borderRadius: 8}}>
            {t('lets-join')}
          </Button>
        </div>
      </div>

      <Button as="link-out" href={rulesDocLink} size="small" style={{borderRadius: 8}} >
        {t('lets-help-together-button')}
      </Button>
    </div>
  );
};
